<template>
	<v-card class="my-2 mx-auto d-flex" max-width="1200" ripple :to="{ name: 'Event', params: { id: attendance.event.id } }" rounded="xl">
		<v-img class="ma-2 flex-grow-0" contain :width="!$vuetify.breakpoint.smAndDown ? 150 : 90" :src="attendance.event.imgURL" />
		<div class="flex-grow-1 mb-2">
			<v-card-title class="mb-2 text-uppercase font-weight-bold" style="word-break: normal">
				{{ attendance.event.title }}
			</v-card-title>
			<v-card-subtitle class="pb-1" style="word-break: normal">
				<v-icon class="mr-1"> mdi-domain </v-icon>
				<template v-if="attendance.event.companyName">
					<i18n path="offers.managedBy" tag="span">
						<template #name>
							<span style="color: #ff6f00">{{ attendance.event.companyName }}</span>
						</template>
						<template #parent>
							<span class="text-subtitle-2 text-decoration-none red--text">
								{{ attendance.event.company.name }}
							</span>
						</template>
					</i18n>
				</template>
				<template v-else>
					<span class="text-subtitle-2 text-decoration-none red--text" style="color: #ff6f00">
						{{ attendance.event.company.name }}
					</span>
				</template>
			</v-card-subtitle>
			<v-card-text class="pb-0">
				<p>
					<span style="color: orange; word-break: normal">{{ attendance.event.type.name }}</span> <v-icon>mdi-map-marker</v-icon>
					{{ attendance.event.info.location }}
				</p>
				<div v-if="attendance.event.type.code == 'interview'">
					<span class="text-body-1"><v-icon>mdi-calendar</v-icon> {{ humanTime(attendance.time) }}</span>
				</div>
				<div v-else>
					<span class="text-body-1"><v-icon>mdi-calendar</v-icon> {{ humanTime(attendance.event.info.timestamp.start) }}</span>
				</div>
				<div class="mt-1" v-if="attendance.event.type.code == 'workshop'">
					<span class="text-body-2" :class="statusClass">
						<v-icon :class="statusClass">{{ statusIcon }}</v-icon> {{ statusText }}
					</span>
				</div>
			</v-card-text>
		</div>
	</v-card>
</template>

<script>
export default {
	name: 'MyEventCard',
	props: {
		attendance: {
			type: Object,
			required: true,
		},
	},
	computed: {
		leftCapacityNumber() {
			if (this.attendance.event.type.code == 'interview') {
				let remaining = this.attendance.event.attendances.spotsLeft || 0
				return remaining > 10 ? Math.floor(remaining / 5) * 5 : remaining
			} else {
				let remaining = this.attendance.event.attendances.maxCapacity - this.attendance.event.attendances.numTotal || 0
				return remaining > 10 ? Math.floor(remaining / 5) * 5 : remaining
			}
		},
		statusIcon() {
			if (this.attendance.status.normal) {
				return 'mdi-clock-outline'
			} else if (this.attendance.status.prioritized) {
				return 'mdi-check-outline'
			} else if (this.attendance.status.discarded) {
				return 'mdi-close-circle-outline'
			} else {
				return ''
			}
		},
		statusText() {
			if (this.attendance.status.normal) {
				return this.$t('attendances.pendingRequest')
			} else if (this.attendance.status.prioritized) {
				return this.$t('attendances.acceptedRequest')
			} else if (this.attendance.status.discarded) {
				return this.$t('attendances.rejectedRequest')
			} else {
				return ''
			}
		},
		statusClass() {
			if (this.attendance.status.normal) {
				return 'blue-grey--text'
			} else if (this.attendance.status.prioritized) {
				return 'green--text'
			} else if (this.attendance.status.discarded) {
				return 'red--text'
			} else {
				return ''
			}
		},
	},
	methods: {
		humanTime(timestamp) {
			return this.$moment.utc(timestamp).format('YYYY-MM-DD HH:mm:ss')
		},
	},
}
</script>
